html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4b4eff;
  overflow: auto;
  font-family: 'Poppins-Medium';
}

:root {
  /* Primary Colors */
  --primary-50: #ecf1ff;
  --primary-100: #dde5ff;
  --primary-200: #c2cfff;
  --primary-300: #9caeff;
  --primary-400: #7581ff;
  --primary-500: #4b4eff;
  --primary-600: #4236f5;
  --primary-700: #382ad8;
  --primary-800: #2e25ae;
  --primary-900: #292689;

  /* Black & Gray Colors */
  --black-gray-gray-50: #f7fafc;
  --black-gray-gray-100: #edf2f7;
  --black-gray-gray-200: #e2e8f0;
  --black-gray-gray-300: #cbd5e0;
  --black-gray-gray-400: #a0aec0;
  --black-gray-gray-500: #718096;
  --black-gray-gray-600: #4a5568;
  --black-gray-black-700: rgb(45, 55, 72);
  --black-gray-black-800: #1a202c;
  --black-gray-black-900: #171923;



  --colors-black-gray-black-700: rgba(45, 55, 72, 1);
  --colors-black-gray-black-800: rgba(26, 32, 44, 1);
  --colors-black-gray-black-900: rgba(23, 25, 35, 1);
  --colors-black-gray-gray-100: rgba(237, 242, 247, 1);
  --colors-black-gray-gray-200: rgba(226, 232, 240, 1);
  --colors-black-gray-gray-300: rgba(203, 213, 224, 1);
  --colors-black-gray-gray-400: rgba(160, 174, 192, 1);
  --colors-black-gray-gray-50: rgba(247, 250, 252, 1);
  --colors-black-gray-gray-500: rgba(113, 128, 150, 1);
  --colors-black-gray-gray-600: rgba(74, 85, 104, 1);
  --colors-cyan-cyan-100: rgba(196, 241, 249, 1);
  --colors-cyan-cyan-200: rgba(157, 236, 249, 1);
  --colors-cyan-cyan-300: rgba(118, 228, 247, 1);
  --colors-cyan-cyan-400: rgba(11, 197, 234, 1);
  --colors-cyan-cyan-50: rgba(237, 253, 253, 1);
  --colors-cyan-cyan-500: rgba(0, 181, 216, 1);
  --colors-cyan-cyan-600: rgba(0, 163, 196, 1);
  --colors-cyan-cyan-700: rgba(9, 135, 160, 1);
  --colors-cyan-cyan-800: rgba(8, 111, 131, 1);
  --colors-cyan-cyan-900: rgba(6, 86, 102, 1);
  --colors-fuchsia-fuchsia-100: rgba(250, 232, 255, 1);
  --colors-fuchsia-fuchsia-200: rgba(245, 208, 254, 1);
  --colors-fuchsia-fuchsia-300: rgba(240, 171, 252, 1);
  --colors-fuchsia-fuchsia-400: rgba(232, 121, 249, 1);
  --colors-fuchsia-fuchsia-50: rgba(253, 244, 255, 1);
  --colors-fuchsia-fuchsia-500: rgba(217, 70, 239, 1);
  --colors-fuchsia-fuchsia-600: rgba(192, 38, 211, 1);
  --colors-fuchsia-fuchsia-700: rgba(162, 28, 175, 1);
  --colors-fuchsia-fuchsia-800: rgba(134, 25, 143, 1);
  --colors-fuchsia-fuchsia-900: rgba(112, 26, 117, 1);
  --colors-green-green-100: rgba(220, 252, 231, 1);
  --colors-green-green-200: rgba(187, 247, 208, 1);
  --colors-green-green-300: rgba(134, 239, 172, 1);
  --colors-green-green-400: rgba(74, 222, 128, 1);
  --colors-green-green-50: rgba(240, 253, 244, 1);
  --colors-green-green-500: rgba(34, 197, 94, 1);
  --colors-green-green-600: rgba(22, 163, 74, 1);
  --colors-green-green-700: rgba(21, 128, 61, 1);
  --colors-green-green-800: rgba(22, 101, 52, 1);
  --colors-green-green-900: rgba(20, 83, 45, 1);
  --colors-lime-lime-100: rgba(236, 252, 203, 1);
  --colors-lime-lime-200: rgba(217, 249, 157, 1);
  --colors-lime-lime-300: rgba(190, 242, 100, 1);
  --colors-lime-lime-400: rgba(163, 230, 53, 1);
  --colors-lime-lime-50: rgba(247, 254, 231, 1);
  --colors-lime-lime-500: rgba(132, 204, 22, 1);
  --colors-lime-lime-600: rgba(101, 163, 13, 1);
  --colors-lime-lime-700: rgba(77, 124, 15, 1);
  --colors-lime-lime-800: rgba(63, 98, 18, 1);
  --colors-lime-lime-900: rgba(54, 83, 20, 1);
  --colors-orange-orange-100: rgba(255, 237, 213, 1);
  --colors-orange-orange-200: rgba(254, 215, 170, 1);
  --colors-orange-orange-300: rgba(253, 186, 116, 1);
  --colors-orange-orange-400: rgba(251, 146, 60, 1);
  --colors-orange-orange-50: rgba(255, 247, 237, 1);
  --colors-orange-orange-500: rgba(249, 115, 22, 1);
  --colors-orange-orange-600: rgba(234, 88, 12, 1);
  --colors-orange-orange-700: rgba(194, 65, 12, 1);
  --colors-orange-orange-800: rgba(154, 52, 18, 1);
  --colors-orange-orange-900: rgba(124, 45, 18, 1);
  --colors-pink-pink-100: rgba(254, 215, 226, 1);
  --colors-pink-pink-200: rgba(251, 182, 206, 1);
  --colors-pink-pink-300: rgba(246, 135, 179, 1);
  --colors-pink-pink-400: rgba(237, 100, 166, 1);
  --colors-pink-pink-50: rgba(255, 245, 247, 1);
  --colors-pink-pink-500: rgba(213, 63, 140, 1);
  --colors-pink-pink-600: rgba(184, 50, 128, 1);
  --colors-pink-pink-700: rgba(151, 38, 109, 1);
  --colors-pink-pink-800: rgba(112, 36, 89, 1);
  --colors-pink-pink-900: rgba(82, 27, 65, 1);
  --colors-primary-primary-100: rgba(221, 229, 255, 1);
  --colors-primary-primary-200: rgba(194, 207, 255, 1);
  --colors-primary-primary-300: rgba(156, 174, 255, 1);
  --colors-primary-primary-400: rgba(117, 129, 255, 1);
  --colors-primary-primary-50: rgba(236, 241, 255, 1);
  --colors-primary-primary-500: rgba(75, 78, 255, 1);
  --colors-primary-primary-600: rgba(66, 54, 245, 1);
  --colors-primary-primary-700: rgba(56, 42, 216, 1);
  --colors-primary-primary-800: rgba(46, 37, 174, 1);
  --colors-primary-primary-900: rgba(41, 38, 137, 1);
  --colors-purple-purple-100: rgba(233, 216, 253, 1);
  --colors-purple-purple-200: rgba(214, 188, 250, 1);
  --colors-purple-purple-300: rgba(183, 148, 244, 1);
  --colors-purple-purple-400: rgba(159, 122, 234, 1);
  --colors-purple-purple-50: rgba(250, 245, 255, 1);
  --colors-purple-purple-500: rgba(128, 90, 213, 1);
  --colors-purple-purple-600: rgba(107, 70, 193, 1);
  --colors-purple-purple-700: rgba(85, 60, 154, 1);
  --colors-purple-purple-800: rgba(68, 51, 122, 1);
  --colors-purple-purple-900: rgba(50, 38, 89, 1);
  --colors-red-red-100: rgba(254, 226, 226, 1);
  --colors-red-red-200: rgba(254, 202, 202, 1);
  --colors-red-red-300: rgba(252, 165, 165, 1);
  --colors-red-red-400: rgba(248, 113, 113, 1);
  --colors-red-red-50: rgba(254, 242, 242, 1);
  --colors-red-red-500: rgba(239, 68, 68, 1);
  --colors-red-red-600: rgba(220, 38, 38, 1);
  --colors-red-red-700: rgba(185, 28, 28, 1);
  --colors-red-red-800: rgba(153, 27, 27, 1);
  --colors-red-red-900: rgba(127, 29, 29, 1);
  --colors-sky-sky-100: rgba(224, 242, 254, 1);
  --colors-sky-sky-200: rgba(186, 230, 253, 1);
  --colors-sky-sky-300: rgba(125, 211, 252, 1);
  --colors-sky-sky-400: rgba(56, 189, 248, 1);
  --colors-sky-sky-50: rgba(240, 249, 255, 1);
  --colors-sky-sky-500: rgba(14, 165, 233, 1);
  --colors-sky-sky-600: rgba(2, 132, 199, 1);
  --colors-sky-sky-700: rgba(3, 105, 161, 1);
  --colors-sky-sky-800: rgba(7, 89, 133, 1);
  --colors-sky-sky-900: rgba(12, 74, 110, 1);
  --colors-teal-teal-100: rgba(204, 251, 241, 1);
  --colors-teal-teal-200: rgba(153, 246, 228, 1);
  --colors-teal-teal-300: rgba(94, 234, 212, 1);
  --colors-teal-teal-400: rgba(45, 212, 191, 1);
  --colors-teal-teal-50: rgba(240, 253, 250, 1);
  --colors-teal-teal-500: rgba(20, 184, 166, 1);
  --colors-teal-teal-600: rgba(13, 148, 136, 1);
  --colors-teal-teal-700: rgba(15, 118, 110, 1);
  --colors-teal-teal-800: rgba(17, 94, 89, 1);
  --colors-teal-teal-900: rgba(19, 78, 74, 1);
  --colors-violet-violet-100: rgba(237, 233, 254, 1);
  --colors-violet-violet-200: rgba(221, 214, 254, 1);
  --colors-violet-violet-300: rgba(196, 181, 253, 1);
  --colors-violet-violet-400: rgba(167, 139, 250, 1);
  --colors-violet-violet-50: rgba(245, 243, 255, 1);
  --colors-violet-violet-500: rgba(139, 92, 246, 1);
  --colors-violet-violet-600: rgba(124, 58, 237, 1);
  --colors-violet-violet-700: rgba(109, 40, 217, 1);
  --colors-violet-violet-800: rgba(91, 33, 182, 1);
  --colors-violet-violet-900: rgba(76, 29, 149, 1);
  --colors-white-white-50: rgba(255, 255, 255, 1);
  --colors-yellow-yellow-100: rgba(254, 249, 195, 1);
  --colors-yellow-yellow-200: rgba(254, 240, 138, 1);
  --colors-yellow-yellow-300: rgba(253, 224, 71, 1);
  --colors-yellow-yellow-400: rgba(250, 204, 21, 1);
  --colors-yellow-yellow-50: rgba(254, 252, 232, 1);
  --colors-yellow-yellow-500: rgba(234, 179, 8, 1);
  --colors-yellow-yellow-600: rgba(202, 138, 4, 1);
  --colors-yellow-yellow-700: rgba(161, 98, 7, 1);
  --colors-yellow-yellow-800: rgba(133, 77, 14, 1);
  --colors-yellow-yellow-900: rgba(113, 63, 18, 1);
  --screens-2xl: 1600px;
  --screens-lg: 1280px;
  --screens-md: 768px;
  --screens-sm: 640px;
  --screens-xl: 1440px;
  --shadow-md: 4px 4px 5px 0px rgba(226, 232, 240, 0.25);
  --shadow-sm: 0px 4px 4px 0px rgba(226, 232, 240, 0.15);
  --shadow-switch: 0px 3px 1px 0px rgba(0, 0, 0, 0.06), 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
  --spacing-spacing-12: 12px;
  --spacing-spacing-16: 16px;
  --spacing-spacing-18: 18px;
  --spacing-spacing-24: 24px;
  --spacing-spacing-32: 32px;
  --spacing-spacing-4: 4px;
  --spacing-spacing-40: 40px;
  --spacing-spacing-48: 48px;
  --spacing-spacing-56: 56px;
  --spacing-spacing-6: 6px;
  --spacing-spacing-64: 64px;
  --spacing-spacing-72: 72px;
  --spacing-spacing-8: 8px;

  --chakra-colors-chakra-border-color: ;
}

.selectize-control.multi {
  min-height: 43px;
}

.selectize-input.items.not-full.has-options {
  min-height: 37px;
}

.selectize-dropdown {
  z-index: 999999999999999999 !important;
}

.rotating-img {
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
