body {
  margin: 0;
  font-family: "Poppins-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Comfortaa-Bold;
  src: url("../../public/fonts/Comfortaa-Bold.ttf");
}

@font-face {
  font-family: Comfortaa-Light;
  src: url("../../public/fonts/Comfortaa-Light.ttf");
}

@font-face {
  font-family: Comfortaa-Medium;
  src: url("../../public/fonts/Comfortaa-Medium.ttf");
}

@font-face {
  font-family: Comfortaa-Regular;
  src: url("../../public/fonts/Comfortaa-Regular.ttf");
}

@font-face {
  font-family: Comfortaa-SemiBold;
  src: url("../../public/fonts/Comfortaa-SemiBold.ttf");
}

@font-face {
  font-family: Poppins-Black;
  src: url("../../public/fonts/Poppins-Black.ttf");
}

@font-face {
  font-family: Poppins-BlackItalic;
  src: url("../../public/fonts/Poppins-BlackItalic.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../../public/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-BoldItalic;
  src: url("../../public/fonts/Poppins-BoldItalic.ttf");
}

@font-face {
  font-family: Poppins-ExtraBold;
  src: url("../../public/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: Poppins-ExtraBoldItalic;
  src: url("../../public/fonts/Poppins-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: Poppins-ExtraLight;
  src: url("../../public/fonts/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: Poppins-ExtraLightItalic;
  src: url("../../public/fonts/Poppins-ExtraLightItalic.ttf");
}

@font-face {
  font-family: Poppins-Italic;
  src: url("../../public/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: Poppins-Light;
  src: url("../../public/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: Poppins-LightItalic;
  src: url("../../public/fonts/Poppins-LightItalic.ttf");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("../../public/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-MediumItalic;
  src: url("../../public/fonts/Poppins-MediumItalic.ttf");
}

@font-face {
  font-family: Poppins-Regular;
  src: url("../../public/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../../public/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins-SemiBoldItalic;
  src: url("../../public/fonts/Poppins-SemiBoldItalic.ttf");
}

@font-face {
  font-family: Poppins-Thin;
  src: url("../../public/fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: Poppins-ThinItalic;
  src: url("../../public/fonts/Poppins-ThinItalic.ttf");
}


html {
  scroll-behavior: smooth;
  max-width: 100%;
}
.scrollbarsidebar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  margin-left: 10px;
}
.scrollbarsidebar::-webkit-scrollbar-track{
  background-color: rgba(255, 255, 255, 0.192);
  scroll-behavior: smooth;
  margin-left: 10px;
  border-radius: 4px;
}

.scrollbarsidebar::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}
.kanbansidebar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  margin-left: 10px;
}
.kanbansidebar::-webkit-scrollbar-track{
  background-color: rgba(255, 255, 255, 0.192);
  scroll-behavior: smooth;
  margin-left: 10px;
  border-radius: 4px;
}

.kanbansidebar::-webkit-scrollbar-thumb {
  background: rgba(143, 143, 143, 0.733);
  border-radius: 5px;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.rotate-forever {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-reverse {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.selectize-control.multi .selectize-input [data-value] {
  text-shadow: 0 1px 0 #4b4eff !important;
  background-color: #4b4eff !important;
  background-image: linear-gradient(to bottom, #6b6df6, #4b4eff) !important;
  box-shadow: 0 1px 0 #4b4eff78, inset 0 1px rgb(255 255 255 / 0%) !important;
}

.selectize-control.plugin-remove_button [data-value] .remove {
  border-left: 1px solid #4b4eff !important;
}

.icon-bg-blue {
  background-color: #4b4dff;
  border-radius: 20%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.icon-bg-blue button:hover {
  background-color: #2b2dfe !important;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
}

.input_multi_select_disabled {
  border: 1px solid #d0d0d0;
  padding: 8px 8px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 36px;
  text-indent: 0 !important;
  color: #303030ad;
  font-family: inherit;
  font-size: 13px;
  -webkit-font-smoothing: inherit;
}

.chakra-alert__title {
  font-family: Poppins-Medium;
  font-size: 14px;
  font-weight: 400;
}

.chakra-alert__desc {
  font-family: Poppins-Medium;
  font-size: 12px;
  font-weight: 400;
}

.popover-arrow {
  display: none !important;
}

.round-shape {
  animation: react-placeholder-pulse 1.5s infinite;
}

.rect-shape {
  animation: react-placeholder-pulse 1.5s infinite;
}

.recurrency-day {
  font-family: 'Poppins-Medium';
}

.rdp-months {
  font-family: 'Poppins-Medium';
}

.daypicker {
  font-family: 'Poppins-Medium';
}

.chakra-radio__control[aria-checked=true], .chakra-radio__control[data-checked] {
  color: #4b4eff !important;
  border-color: #e2e8f0 !important;
  background: #4b4eff !important;
}

.chakra-radio__control[aria-checked=true]::before, .chakra-radio__control[data-checked]::before {
  width: 97%;
  height: 97%;
}