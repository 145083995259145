.custom-selectize-item,
.custom-selectize-option {
  font-size: 12px;
  font-family: 'Poppins-medium', sans-serif;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  color: #4b4eff;
}

.custom-selectize-item {
  padding: 5px;
  margin: 2px 0;
  color: #4b4eff!important;
}
.custom-selectize-item.active {
  border-left: 1px solid var(--primary-200)!important;
}

.custom-selectize-option {
  padding: 10px;
  cursor: pointer;
}

.custom-selectize-option:hover {
  background-color: #e0e0e0;
}

.custom-select {
  width: 100%;
}

.custom-select .selectize-input {
  box-shadow: none !important;
  background-color: transparent !important;
  color: #4b4eff !important;
  font-family: 'Poppins-medium', sans-serif !important;
  font-size: 12px !important;
  background-image: none !important;
}

.selectize-input.items.full.has-items {
  border: 1px solid #CBD5E0;
  border-radius: 4px !important;
  padding: 5px 0;
}

.selectize-input.input-active {
  border-radius: 4px !important;
  min-height: 42px!important;
  max-height: 42px!important;
  margin: 0!important;
}

.selectize-input.input-active:focus {
  border-radius: 4px !important;
  padding-top: 10px!important;
}

.selectize-input.input-active {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

.selectize-input.items.full.has-items span {
  color: #4b4eff;
  background-color: #dde5ff;
  border-radius: 15px;
  width: fit-content;
}

.selectize-input.items.full.has-items span a {
  top: 7px;
  color: #4b4eff;
  margin-right: 5px;
}

.selectize-input.items.not-full {
  display: flex;
  height: 42px;
  border-color: #CBD5E0;
}

.selectize-control.multi .selectize-input [data-value] {
  background-image: none !important;
  background-color: #dde5ff!important;
  color: transparent!important;
  border: none!important;
  padding-top: 5px!important;
  font-family: 'Poppins-Medium', serif;
}

.selectize-control.multi .remove {
  padding: 5px!important;
  margin-right:5px;
  font-family: 'Poppins-Medium', serif;
}

.selectize-control.plugin-remove_button [data-value] .remove {
  border-left: 1px solid var(--primary-200)!important;
}
.selectize-control.plugin-remove_button [data-value] .remove:hover {
  background-color: #FFBDAD;
  color: #DE350B;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  right: -5px;
  width: 23px;
  box-sizing: border-box;
}

.selectize-dropdown {
  height: auto!important;
}

.create.active {
  padding: 6px;
}

.selectize-dropdown-content{
  padding-top: 0!important;
}