.alert {
  height: 56px !important;
  width: 100%;
  align-items: center;
  position: relative;
}

.alert .alert-icon {
  border-radius: 12px;
  height: 24px;
  position: relative;
  width: 24px;
}

.alert .icons {
  height: 16px !important;
  left: 4px !important;
  position: absolute !important;
  top: 4px !important;
  width: 16px !important;
}

.alert .stack {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.alert .alert-title {
  align-self: stretch;
  font-family: "Poppins", Helvetica;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.alert .alert-description {
  align-self: stretch;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
}

.alert .div {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.alert .icons-wrapper {
  border-radius: 12px;
  height: 24px;
  position: relative;
  width: 24px;
}

.alert .message {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.alert .text-wrapper {
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.alert .instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.alert .left {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
}

.alert .alert-title-2 {
  color: var(--colors-black-gray-black-900);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.alert .alert-clicable {
  color: var(--colors-primary-primary-600);
  flex: 1;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  cursor: pointer; 
}

.alert .action {
  align-items: center;
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 0 16px 16px;
  cursor: pointer;
  position: relative;
}

.alert.variant-top-accent {
  border-top-style: solid;
  border-top-width: 4px;
}

.alert.three {
  border: 1px solid;
  border-radius: 6px;
  box-shadow: 0px 4px 4px #00000040;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.alert.start {
  justify-content: start;
}

.alert.one {
  border-radius: 6px 6px 0px 0px;
  display: flex;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 16px;
  width: 400px;
}

.alert.two {
  border-radius: var(--spacing-spacing-6);
  display: flex;
  gap: 126px;
  padding: var(--spacing-spacing-8) var(--spacing-spacing-16) var(--spacing-spacing-8) var(--spacing-spacing-16);
}

.alert.variant-left-accent {
  border-left-style: solid;
  border-left-width: 4px;
}

.alert.info.variant-subtle {
  background-color: var(--colors-cyan-cyan-100);
}

.alert.variant-alert.success {
  background-color: var(--colors-green-green-100);
  border-color: var(--colors-green-green-500);
}

.alert.variant-solid.error {
  background-color: var(--colors-red-red-700);
}

.alert.variant-solid.warning {
  background-color: var(--colors-yellow-yellow-500);
}

.alert.variant-top-accent.success {
  background-color: var(--colors-green-green-100);
  border-color: var(--colors-green-green-700);
}

.alert.warning.variant-alert {
  background-color: var(--colors-yellow-yellow-100);
  border-color: var(--colors-yellow-yellow-500);
}

.alert.variant-alert.error {
  background-color: var(--colors-red-red-100);
  border-color: var(--colors-red-red-500);
}

.alert.warning.variant-left-accent {
  background-color: var(--colors-orange-orange-100);
  border-color: var(--colors-orange-orange-700);
}

.alert.variant-left-accent.error {
  background-color: var(--colors-red-red-100);
  border-color: var(--colors-red-red-700);
}

.alert.variant-top-accent.error {
  background-color: var(--colors-red-red-100);
  border-color: var(--colors-red-red-700);
}

.alert.variant-top-accent.warning {
  background-color: var(--colors-orange-orange-100);
  border-color: var(--colors-orange-orange-700);
}

.alert.variant-left-accent.success {
  background-color: var(--colors-green-green-100);
  border-color: var(--colors-green-green-700);
}

.alert.info.variant-alert {
  background-color: var(--colors-primary-primary-100);
  border-color: var(--colors-primary-primary-500);
}

.alert.info.variant-left-accent {
  background-color: var(--colors-cyan-cyan-100);
  border-color: var(--colors-cyan-cyan-700);
}

.alert.variant-subtle.success {
  background-color: var(--colors-green-green-100);
}

.alert.variant-solid.info {
  background-color: var(--colors-cyan-cyan-700);
}

.alert.variant-subtle.error {
  background-color: var(--colors-red-red-100);
}

.alert.variant-top-accent.info {
  background-color: var(--colors-cyan-cyan-100);
  border-color: var(--colors-cyan-cyan-700);
}

.alert.warning.one.variant-subtle {
  background-color: var(--colors-orange-orange-100);
}

.alert.variant-solid.one.success {
  background-color: var(--colors-green-green-700);
}

.alert.variant-solid.two.success {
  background-color: var(--colors-green-green-600);
}

.alert.warning.variant-subtle.two {
  background-color: var(--colors-yellow-yellow-100);
}

.alert.variant-solid .alert-icon {
  background-color: var(--colors-white-white-50);
}

.alert.info.variant-subtle .alert-icon {
  background-color: var(--colors-cyan-cyan-600);
}

.alert.variant-top-accent.success .alert-icon {
  background-color: var(--colors-green-green-600);
}

.alert.warning.variant-left-accent .alert-icon {
  background-color: var(--colors-orange-orange-600);
}

.alert.variant-left-accent.error .alert-icon {
  background-color: var(--colors-red-red-600);
}

.alert.variant-top-accent.error .alert-icon {
  background-color: var(--colors-red-red-600);
}

.alert.variant-top-accent.warning .alert-icon {
  background-color: var(--colors-orange-orange-600);
}

.alert.variant-left-accent.success .alert-icon {
  background-color: var(--colors-green-green-600);
}

.alert.warning.variant-subtle .alert-icon {
  background-color: var(--colors-orange-orange-600);
}

.alert.info.variant-left-accent .alert-icon {
  background-color: var(--colors-cyan-cyan-600);
}

.alert.variant-subtle.success .alert-icon {
  background-color: var(--colors-green-green-600);
}

.alert.variant-subtle.error .alert-icon {
  background-color: var(--colors-red-red-600);
}

.alert.variant-top-accent.info .alert-icon {
  background-color: var(--colors-cyan-cyan-600);
}

.alert.variant-subtle .alert-title {
  color: var(--colors-black-gray-black-900);
  font-size: 14px;
  font-weight: 600;
}

.alert.variant-top-accent .alert-title {
  color: var(--colors-black-gray-black-900);
  font-size: 14px;
  font-weight: 600;
}

.alert.warning .alert-title {
  color: var(--colors-black-gray-black-900);
  font-size: 14px;
  font-weight: 600;
}

.alert.info .alert-title {
  font-size: 14px;
  font-weight: 600;
}

.alert.variant-left-accent .alert-title {
  color: var(--colors-black-gray-black-900);
  font-size: 14px;
  font-weight: 600;
}

.alert.error .alert-title {
  font-size: 14px;
  font-weight: 600;
}

.alert.variant-solid.success .alert-title {
  color: var(--colors-white-white-50);
  font-size: 16px;
  font-weight: 500;
}

.alert.variant-solid.error .alert-title {
  color: var(--colors-white-white-50);
}

.alert.variant-solid.info .alert-title {
  color: var(--colors-white-white-50);
}

.alert.variant-subtle .alert-description {
  color: var(--colors-black-gray-gray-600);
}

.alert.variant-top-accent .alert-description {
  color: var(--colors-black-gray-gray-600);
}

.alert.variant-left-accent .alert-description {
  color: var(--colors-black-gray-gray-600);
}

.alert.variant-solid.success .alert-description {
  color: var(--colors-black-gray-gray-100);
}

.alert.variant-solid.error .alert-description {
  color: var(--colors-black-gray-gray-100);
}

.alert.variant-solid.warning .alert-description {
  color: var(--colors-black-gray-black-900);
}

.alert.variant-solid.info .alert-description {
  color: var(--colors-black-gray-gray-100);
}

.alert.variant-solid .icons-wrapper {
  background-color: var(--colors-white-white-50);
}

.alert.info.variant-subtle .icons-wrapper {
  background-color: var(--colors-cyan-cyan-600);
}

.alert.warning.variant-subtle .icons-wrapper {
  background-color: var(--colors-orange-orange-600);
}

.alert.variant-subtle.success .icons-wrapper {
  background-color: var(--colors-green-green-600);
}

.alert.variant-subtle.error .icons-wrapper {
  background-color: var(--colors-red-red-600);
}

.alert.variant-subtle .text-wrapper {
  color: var(--colors-black-gray-black-900);
}

.alert.warning .text-wrapper {
  color: var(--colors-black-gray-black-900);
}

.alert.variant-solid.success .text-wrapper {
  color: var(--colors-white-white-50);
}

.alert.variant-solid.error .text-wrapper {
  color: var(--colors-white-white-50);
}

.alert.variant-solid.info .text-wrapper {
  color: var(--colors-white-white-50);
}

.alert.warning .action {
  border-color: var(--colors-yellow-yellow-500);
}

.alert.error .action {
  border-color: var(--colors-red-red-500);
}

.alert.info .action {
  border-color: var(--colors-primary-primary-500);
}

.alert.success .action {
  border-color: var(--colors-green-green-500);
}
