.custom-scroll {

  &::-webkit-scrollbar {
    width: 5px;
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    background: #bdbec196;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5a9b7;
    border-radius: 20px;
  }
}

.custom-word-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rdp {
  --rdp-accent-color: #4b4eff !important;
  --rdp-cell-size: 26px;
  --rdp-caption-font-size: 14px;
}

.rdp-caption_label {
  font-weight: 400;
}

.rdp-head_cell {
  font-weight: 400;
}

.rdp-day {
  border-radius: 6px;
  margin: 4px;
  font-size: 12px;
}

.recurrency-day {
  background-color: #ECF1FF !important;
  color: black;
}

.react-tabs {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins-Medium', serif;
  height: 100%;
  border: 1px solid var(--black-gray-gray-300);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.react-tabs__tab-panel {
  flex-direction: column;
}


.react-tabs__tab-panel--selected {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  border-top: 1px solid var(--black-gray-gray-300);
}

.react-tabs__tab-list {
  margin: -1px 0 0 0;
  font-family: 'Poppins-Medium', serif;
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #2c3034;
  border: none;
}

.react-tabs__tab {
  font-family: 'Poppins-Medium', serif;
  color: #2c3034 !important;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 50px;
}

.react-tabs__tab--selected {
  font-family: 'Poppins-Medium', serif;
  font-size: 12px;
  color: white !important;
  background: var(--primary-500) !important;
  border: none;
}

.react-tabs__tab--selected:after {
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
}