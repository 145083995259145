@import "selectize/dist/css/selectize.default.css";

.selectize-dropdown-content {
  padding-top: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #bdbec196;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a5a9b7;
    border-radius: 20px;
  }
}

.selectize-input {
  max-height: 74px;
}

.selectize-input.input-active {
  overflow: auto;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #bdbec196;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a5a9b7;
    border-radius: 20px;
  }
}

.selectize-input.input-active {
  height: auto;
  min-height: 45px;
}

.selectize-input.dropdown-active {
  height: auto;
  min-height: 45px;
}

.selectize-input.input-active.has-items {
  height: auto;
  max-height: 136px;
  background-color: #f4f4fb;
}

.selectize-input.dropdown-active.has-items {
  height: auto;
  max-height: 136px;
  background-color: #f4f4fb;
}

/* .selectize-input > * {
  display: inline-flex;
} */

.selectize-input .item {
}

.selectize-control.multi .selectize-input [data-value] {
  border-radius: 100px;
  background: #0263ff;
  /* display: inline-flex; */
  /* padding: 1px; */
}

/* .selectize-control.multi .selectize-input [data-value] a {
  position: relative;
  text-align: center;
  width: 100%;
} */

/* .selectize-control.plugin-remove_button [data-value] {
  position: relative;
  padding-left: 24px !important; 
} */

/* .selectize-control.plugin-remove_button [data-value] {
  position: relative;
  padding-right: 24px !important;
  display: inline-flex;
  align-items: center;
}

.selectize-control.plugin-remove_button [data-value] .remove {
  z-index: 1;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 17px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  padding: 2px;
  border-left: 1px solid #0073bb;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */
